import { Modal } from 'bootstrap';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getFunctions, httpsCallable } from 'firebase/functions';
import tlds from 'tlds';

const firebaseConfig = {
    apiKey: process.env['firebase.apiKey'],
    authDomain: process.env['firebase.authDomain'],
    projectId: process.env['firebase.projectId'],
    storageBucket: process.env['firebase.storageBucket'],
    messagingSenderId: process.env['firebase.messagingSenderId'],
    appId: process.env['firebase.appId'],
    measurementId: process.env['firebase.measurementId'],
};
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, 'europe-west3');
const isExistingCustomer = httpsCallable(functions, 'isExistingCustomer');
const submitForm = httpsCallable(functions, 'submitForm');

async function onDomainChange() {
    const submitButton = document.getElementById('submit');
    const domainInput = document.getElementById('customerDomain');
    const customerAuthTokenInput = document.getElementById('customerAuthToken');
    const customerAuthToken = customerAuthTokenInput.parentNode;
    const productStatus = document.getElementById('productStatus');

    submitButton.setAttribute('disabled', 'true');
    productStatus.classList.remove('d-none');

    let customerExists;
    const domain = domainInput.value.trim();

    domainInput.setCustomValidity('');
    domainInput.classList.remove('is-invalid');
    if (!tlds.some((tld) => new RegExp(`.+\\.${tld}`).test(domain))) {
        domainInput.setCustomValidity(document.getElementById('domainInvalidFeedback').innerText);
        domainInput.classList.add('is-invalid');
    }

    try {
        customerExists = (await isExistingCustomer({ domain })).data;
    } catch (e) {
        document.getElementById(
            'failureModalErrorText'
        ).innerText = `Error ${e.code}: ${e.message}. ${e.details}`;
        new Modal(document.getElementById('failureModal')).show();
        return;
    } finally {
        submitButton.removeAttribute('disabled');
        productStatus.classList.add('d-none');
    }

    if (!customerExists) {
        customerAuthToken.classList.add('visually-hidden');
        customerAuthTokenInput.required = false;
        return;
    }

    customerAuthToken.classList.remove('visually-hidden');
    customerAuthTokenInput.required = true;
}

function onAlternateEmailChange() {
    const alternateEmailInput = document.getElementById('alternateEmail');
    alternateEmailInput.classList.remove('is-invalid');
    alternateEmailInput.setCustomValidity('');

    const form = document.querySelector('form');
    const formData = new FormData(form);
    const alternateEmail = formData.get('alternateEmail');
    const customerDomain = formData.get('customerDomain');

    if (alternateEmail.includes(customerDomain)) {
        alternateEmailInput.classList.add('is-invalid');
        alternateEmailInput.setCustomValidity(
            document.getElementById('alternateEmailInvalidFeedback').innerText
        );
    }
}

async function onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const submitButton = document.getElementById('submit');
    submitButton.setAttribute('disabled', 'true');
    const progressBar = document.getElementById('progressBar');
    progressBar.style.width = '0%';

    const form = document.querySelector('form');
    if (!form.checkValidity()) {
        return;
    }

    const data = new FormData(form);
    progressBar.style.width = '50%';
    try {
        await submitForm({
            ...Array.from(data.keys()).reduce((result, key) => {
                result[key] = data.get(key);
                return result;
            }, {}),
            language: document.documentElement.lang,
        });
    } catch (_) {
        document.getElementById('failureModalErrorText').innerText = `Error ${e.code || 'no code'}: ${
            e.message || 'no message'
        }. ${e.details || 'no details'}`;
        new Modal(document.getElementById('failureModal')).show();
        progressBar.style.width = '0%';
        return;
    } finally {
        submitButton.removeAttribute('disabled');
    }

    progressBar.style.width = '100%';
    location.href = 'success';
}

window.onBodyLoad = function () {
    initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(process.env['recaptcha.key']),
        isTokenAutoRefreshEnabled: true,
    });

    document.querySelector('form').classList.remove('d-none');
    document.querySelector('form').addEventListener('submit', onSubmit);

    document.getElementById('customerDomain').addEventListener('change', onDomainChange);

    document.getElementById('customerDomain').addEventListener('change', onAlternateEmailChange);
    document.getElementById('alternateEmail').addEventListener('change', onAlternateEmailChange);
};
